import React, { useState, useEffect } from 'react';
import Dropdown from './dropdown';
import { useI18nSection, useLanguage, useSetLanguage } from "../../utils/languageUtils";

import './navbar.css';

const Navbar = () => {
  const languageOptions = {
    en: {
      flag: "fi fi-gb width-size",
      name: "English",
      selectLanguage: "",
    },
    zh: {
      flag: "fi fi-cn width-size",
      name: "中文",
      selectLanguage: "",
    },
    es: {
      flag: "fi fi-es width-size",
      name: "Español",
      selectLanguage: "",
    },
    jp: {
      flag: "fi fi-jp width-size",
      name: "日本語",
      selectLanguage: "",
    },
    vi: {
      flag: "fi fi-vn width-size",
      name: "Tiếng Việt",
      selectLanguage: "",
    },
    de: {
      flag: "fi fi-de width-size",
      name: "Deutsch",
      selectLanguage: "",
    },
  };
  const currentLanguage = useLanguage()

  const sectionText = useI18nSection('nav')
  const setLanguage = useSetLanguage()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const handleChangeLanguage = (language) => {
    setLanguage(language);
    setIsLanguageOpen(false)
  };

  const closeMenu = () => {
    setIsMenuOpen(false)
    setIsLanguageOpen(false)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleLanguageDrpdwn = () =>{
    setIsLanguageOpen(!isLanguageOpen);
  }

  const scrollToBuySection = () => {
    // Find the target section to scroll to
    let section = null;
    // if(isMobile){
    //   section = document.getElementById('buyForm');
    // }else{
    //   section = document.getElementById('intro');
    // // }
    // if (!section) {
      window.location = "https://raydium.io/swap/?inputMint=21v2BECiuZ51rGrUqrZo7ozrhQ7GYnANoGdPyMFbPmvP&outputMint=Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
    //   return
    // }
    // Scroll to the section
    // section.scrollIntoView({ behavior: 'smooth' });
    // closeMenu()
  };

  return (
    <nav className={`appnav-navbar  sticky`}>
      <div className="appnav-navbar-brand">
        <a href="/" style={{fontSize:'50px'}}>
          <img src="/img/trumpwif/trumphatmini1.png" width={"100"} height={"60"}  alt="Brand Logo" className="appnav-logo" />
          {/* TWIF */}
        </a>        
        <button className="appnav-menu-toggle" onClick={toggleMenu}>
          <div className={`appnav-hamburger ${isMenuOpen ? 'appnav-is-active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      
      <div className={`appnav-navbar-menu ${isMenuOpen ? 'appnav-is-active' : ''}`}>
        <a href="/">TRUMP WIF</a>
        <a href="https://twitter.com/realDonaldTrump" target='_blank'>TWITTER</a>
      </div>
      <div className={`appnav-navbar-right ${isMenuOpen ? 'appnav-is-active' : ''}`}>
        <div className="appnav-lang-login-container">
          <button onClick={scrollToBuySection}
              className="appnav-login">{sectionText.buyNow}</button>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
