import React, { useState, useEffect, useMemo } from "react";
import { Navbar, Nav, Container, Row, Col, Card } from "react-bootstrap";
import {
  calculateUSDNeeded,
  calculateTokenOutput,
  calculateTokensForBNB,
  calculateBNBNeeded,
  isValidNumber,
  truncateMiddle,
  CONST,
  useWallet,
} from "../services/wallet-service";
import configs from "../config.main.json";
import { useCountdown } from "../services/utils";
import { useTokenInfo, getUserPurchaseInfo } from "../services/token-service";
import { useI18nSection } from "../utils/languageUtils";
import "./header.css";
export const Header = () => {
  const currencies = [
    { text: 'ETH', imageSrc: '/img/sealana/icon@eth1.svg', curr: CONST.ETH, network: CONST.ETH },
    { text: 'USDT', imageSrc: '/img/sealana/icon@erc20.svg', curr: CONST.ERC20, network: CONST.ETH },
    { text: 'BNB', imageSrc: '/img/sealana/icon@bnb.svg', curr: CONST.BNB, network: CONST.BSC },
    { text: 'USDT', imageSrc: '/img/sealana/icon@bep20.svg', curr: CONST.USDT, network: CONST.BSC },
    // { text: 'SOLANA', imageSrc: '/img/icon@solana.svg', curr:CONST.SOL, network:CONST.SOL },
  ];


  const colors = ['red', 'white', 'blue'];

  const FlagText = ({ text }) => {
    return (
      <div className="text-container">
        {text.split('').map((char, index) => (
          <span key={index} style={{color:colors[index % colors.length], fontWeight:900}}>
            {char}
          </span>
        ))}
      </div>
    );
  };


  return (
    <div id="intro" className="intro" style={{paddingTop: '200px'}}>
      {/* <div className="container"> */}
      <div
        style={{ paddingLeft: "12px", paddingRight: "12px", display: "flex" }}
      >
        <div className="bannerSec">
          <Row style={{ flex: 1 }}>
            <Col md={12} sm={12} className="col-xs-12">
              <h1 style={{color: 'yellow'}}>
                    <FlagText text='TRUMP WITH HAT' />
              </h1>
              <h1 style={{color: 'yellow'}}>
                    <FlagText text='TRUMP LOVE CRYTO' />
              </h1>
              <h1 style={{color: 'yellow'}}>
                    <FlagText text='MAKE AMERICA GREATE AGAIN' />
                    
              </h1>
            </Col>
            
          </Row>
        </div>
      </div>
    </div>
    // </div>
  );
};
